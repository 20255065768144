<template>
  <ion-page>
  <ion-content class="">
    <div class="items-center py-5">

      <div class="ion-text-center flex justify-center mb-5">
        <router-link :to="{ name: 'news' }">
          <ion-img src="/img/logo.svg" class="logo" />
        </router-link>
      </div>

      <form @submit.prevent="registerUser" autocomplete="off" v-show="!registerSuccess">
        <ion-radio-group value="" @ionChange="register.gender = $event.target.value">
          <ion-list-header>
            <ion-label>{{ $t('gender') }}</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>{{ $t('male') }}</ion-label>
            <ion-radio slot="end" color="success" value="MALE" />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('female') }}</ion-label>
            <ion-radio slot="end" color="success" value="FEMALE" />
          </ion-item>
        </ion-radio-group>

        <ion-item class="ion-margin-top">
          <ion-input type="text" :placeholder="$t('firstname')" :value="register.first_name" @ionInput="register.first_name = $event.target.value" />
        </ion-item>
        <ion-item class="ion-margin-top">
          <ion-input type="text" :placeholder="$t('lastname')" :value="register.last_name" @ionInput="register.last_name = $event.target.value" />
        </ion-item>
        <ion-item class="ion-margin-top">
          <ion-input type="email" autocomplete="email" :placeholder="$t('email')" :value="register.email" @ionInput="register.email = $event.target.value" />
        </ion-item>
        <ion-item class="ion-margin-top">
          <ion-input type="email" autocomplete="email" :placeholder="$t('confirmemail')" :value="register.email_confirmation" @ionInput="register.email_confirmation = $event.target.value" />
        </ion-item>
        <ion-item class="ion-margin-top">
          <ion-label>{{ $t('nationality') }}</ion-label>
          <ion-select @ionChange="register.country = $event.target.value" :value="register.country">
            <ion-select-option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id">
              {{ nationality.country }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item class="ion-margin-top">
          <ion-datetime
            class="pl-0"
            display-format="DD-MM-YYYY"
            :placeholder="$t('dateofbirth')"
            picker-format="DD MM YYYY"
            required="true"
            @ionChange="register.birthdate = $event.target.value"
          />
        </ion-item>

        <ShowHidePassword
          class="ion-margin-top"
          :placeholder="$t('password')"
          :value="register.password"
          :passwordVisible="showPassword"
          @input="register.password = $event"
          @showPassword="showPassword = $event"
        />

        <ShowHidePassword
          class="ion-margin-top"
          :placeholder="$t('passwordconfirmation')"
          :value="register.password_confirmation"
          :passwordVisible="showPassword"
          @input="register.password_confirmation = $event"
          @showPassword="showPassword = $event"
        />

        <!-- <ion-item class="flex justify-between">
          <ion-label style="pointer-events: all !important" @click="newFile">
            <ion-text class="text-sm inline-block mb-4">
              {{ $t('uploadportrait') }}
            </ion-text>

            <div class="w-16 h-16 rounded-full border border-gray-500 bg-cover" :style="{ backgroundImage: `url(${register.image})` }" />
          </ion-label>
          <ion-button expand="full" @click="newFile">
            {{ $t('chooseaprofilepicture') }}
          </ion-button>
        </ion-item> -->

        <ion-grid class="mt-4">
          <ion-col>

            <ion-text class="text-sm block">
              {{ $t('basicinformationregister') }}
            </ion-text>

            <i18n tag='ion-text' path='privacypolicy' class='mt-5 text-sm block'>
              <a href="https://motoinside.app/privacybeleid/" target="blank"><ion-text color="moto">{{ $t('privacypolicylabel') }}</ion-text></a>
            </i18n>
          </ion-col>
        </ion-grid>

        <Error :error="error" class="mt-5" />

        <LoadingButton :loading="loading" :disabled="loading" class="ion-margin-top" color="moto" @click="registerUser">
          {{ $t('register') }}
        </LoadingButton>
      </form>

      <div v-show="registerSuccess" class="mt-5 p-3">
        {{ $t('thanksregistration') }}
      </div>

      <ion-button type="submit" expand="full" @click="loginUser">
        {{ $t('backtologin') }}
      </ion-button>
    </div>
  </ion-content>
  </ion-page>
</template>

<script>
    import * as moment from 'moment';

    import { GET_NATIONALITIES } from "@/graphql/queries";
    import { REGISTER_USER } from "@/graphql/mutations";
    import ShowHidePassword from "@/components/inputs/ShowHidePassword.vue";

    export default {
      components: {ShowHidePassword},
      data() {
        return {
          loading: false,
          showPassword: false,
          register: {
            first_name: '',
            last_name: '',
            email: '',
            email_confirmation: '',
            country: '',
            birthdate: '',
            language: '',
            password: '',
            password_confirmation: '',
            gender: '',
          },
          error: null,
          registerSuccess: false
        }
      },
      apollo: {
        nationalities: GET_NATIONALITIES
      },
      watch: {
        nationalities(allNationalities) {
          const nationality = allNationalities.find( (nationality) => nationality.country === 'Netherlands')
          this.register.country = nationality?.id;
        }
      },
      methods: {
        loginUser() {
          this.$router.push({ name: this.$routeNames.LOGIN })
        },
        registerUser() {
            this.error = null;
            this.loading = true;
            this.registerSuccess = false;
            const register = this.register;

            register.birthdate = moment(register.birthdate).format('YYYY-MM-DD');

            this.$apollo.mutate({
              // Query
              mutation: REGISTER_USER,
              variables: {
                data: {
                  gender: register.gender,
                  first_name: register.first_name,
                  last_name: register.last_name,
                  birthdate: register.birthdate,
                  nationality: register.country,
                  email: register.email,
                  email_confirmation: register.email_confirmation,
                  password: register.password,
                  password_confirmation: register.password_confirmation
                }
              }
            }).then(() => {
              // Result
              this.registerSuccess = true;
            }).catch((error) => {
              // Error
              // eslint-disable-next-line no-console
              console.error(error)
              // We restore the initial user input
              this.register = register
              this.error = error;
            }).finally(() => {
              this.loading = false;
            })
        }
      }
    }
</script>
